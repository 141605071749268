import axios from '@/libs/api.request.js'

export function sendCodeSMS(mobile) {
  return axios.request({
    url: '/api/sms',
    data: { mobile },
    method: 'post',
  })
}

export function login(params) {
  return axios.request({
    url: '/api/mobileLogin',
    data: {
      mobile: params.mobile,
      code: params.code,
      zone: params.zone,
    },
    method: 'post',
  })
}
